import React from "react";
import moment from "moment";
import DropdownComplianceBarChartFilter from "components/ui/dropdown/dropdown-compliance-bar-chart-filter";
import { PureModal } from "components/ui";
import { useDispatch } from "react-redux";
import { createTab } from "actions/system-tray";

const DocumentHubTableRow = React.memo(({ row }) => {
  const dispatch = useDispatch();
  const { DocumentTitle, LastModifiedDate, DownloadFileURL, Company } = row;

  return (
    <tr style={{ cursor: "default" }}>
      <td style={{ cursor: "pointer" }}>
        <div>
          <DropdownComplianceBarChartFilter
            trigger={<span>{DocumentTitle}</span>}
          >
            <ul className="dropdown-list">
              <li
                className="dropdown-command"
                onClick={() => document.getElementById("view-doc").click()}
              >
                <PureModal
                  renderTrigger={({ }) => (
                    <div
                      onClick={() => {
                        dispatch(
                          createTab({
                            type: "document-hub",
                            key: "document-hub",
                            label: `${DocumentTitle}`,
                            fileUrl: DownloadFileURL,
                          })
                        );
                      }}
                      style={{ color: "#4281A6" }}
                      id="view-doc"
                    >
                      Open
                    </div>
                  )}
                  renderContent={({ }) => (<div></div>)}
                />
              </li>
              <li className="dropdown-command">
                <a href={DownloadFileURL} target="blank">
                  Open in New Window
                </a>
              </li>
            </ul>
          </DropdownComplianceBarChartFilter>
        </div>
      </td>
      <td>{Company}</td>
      <td>{moment(LastModifiedDate).format("MM/DD/YYYY")}</td>
    </tr>
  );
});

export default DocumentHubTableRow;
