import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InlineWaiting, PageHeader, PureModal } from "components/ui/";
import { Formik, Form } from "formik";
import FormikField from "components/forms/formik-field";
import FormikFieldLeftAligned from "components/forms/formik-field-left-aligned";
import { useFetch } from "hooks";
import { get, put } from "utils/api";
import { errorMessage, message } from "actions/message";
import { useDispatch } from "react-redux";
import ConfirmationModal from "components/shared/confirmation-modal";

const CompanyAppPermissions = ({ companyId }) => {
  const dispatch = useDispatch();

  const [newAppPermissions, setNewAppPermissions] = useState();
  const [onboardingEnabled, setOnboardingEnabled] = useState(false);

  function preventNullValues(permissions) {
    let newPermissions = {
      chatNow: permissions.chatNow ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      documents: permissions.documents ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      orderSupplies: {
        ResupplyPermission:
          permissions?.orderSupplies?.ResupplyPermission ?? false,
        CompliancePermission:
          permissions?.orderSupplies?.CompliancePermission ?? false,
        StatusInactivePermission: false
      },
      myOrders: {
        ResupplyPermission: permissions?.myOrders?.ResupplyPermission ?? false,
        CompliancePermission:
          permissions?.myOrders?.CompliancePermission ?? false,
        StatusInactivePermission: false
      },
      tracking: permissions.tracking ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      signatures: permissions.signatures ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      myProducts: {
        ResupplyPermission:
          permissions?.myProducts?.ResupplyPermission ?? false,
        CompliancePermission:
          permissions?.myProducts?.CompliancePermission ?? false,
        StatusInactivePermission: false
      },
      questionaires: permissions.questionaires ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      scheduleChat: permissions.scheduleChat ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      maskFitting: permissions.maskFitting ?? false,
      dmeAccessRecordings: permissions.dmeAccessRecordings ?? false,
      patientTasks: permissions.patientTasks ?? false,
      onboarding: permissions.onboarding ?? {
        ResupplyPermission: false,
        CompliancePermission: false,
        StatusInactivePermission: false
      },
      bonafideInsuranceVerification:
        permissions.bonafideInsuranceVerification ?? false,
      ra_cmn_integration: permissions.ra_cmn_integration ?? false,
      ra_insurance_integration: permissions.ra_insurance_integration ?? false,
      new_hcpc_eligibility: permissions.new_hcpc_eligibility ?? false,
      allowAutoshipOrders: permissions.allowAutoshipOrders ?? false,
      document_hub: permissions.document_hub ?? false
    };
    setNewAppPermissions(newPermissions);
    setOnboardingEnabled(permissions.onboarding_enabled);
  }

  const { isFetching, fetchData } = useFetch({
    apiFn: companyId => get(`companies/${companyId}/app_permissions`),
    defaultValue: {},
    clearDataOnFetch: false,
    onSuccess: response => preventNullValues(response),
    onError: error =>
      dispatch(
        errorMessage(`Failed to fetch app permissions: ${error.message}`)
      )
  });

  const { fetchData: updateAppPermissions } = useFetch({
    apiFn: permissions =>
      put(`companies/${companyId}/app_permissions`, permissions),
    defaultValue: {},
    onSuccess: () => dispatch(message("Updated app permissions successfully")),
    onError: error =>
      dispatch(
        errorMessage(`Failed to update app permissions: ${error.message}`)
      )
  });

  useEffect(() => {
    fetchData(companyId);
  }, []);

  return (
    <div
      style={{ padding: 10, display: "flex", flexDirection: "column", flex: 1 }}
    >
      <PageHeader title="App Permissions">
        {isFetching && <InlineWaiting />}
      </PageHeader>
      <Formik
        initialValues={newAppPermissions}
        onSubmit={async values => {
          dispatch({
            type: "SET_USER_DOCUMENT_HUB_ACCESS",
            payload: { can_access_document_hub: values.document_hub }
          });
          await updateAppPermissions(values);
          await fetchData(companyId);
        }}
        enableReinitialize={true}
      >
        {({ values, handleReset, isSubmitting, dirty, setFieldValue }) => (
          <Form style={{ padding: 10 }}>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "center"
                }}
              >
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Onboarding"
                    subLabel="New Patient Materials"
                  />
                  <FormikFieldLeftAligned
                    name="onboarding.CompliancePermission"
                    checked={!!values?.onboarding?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                    onChange={() => {
                      if (!onboardingEnabled)
                        document
                          .getElementById("onboardingEmptyWarning")
                          .click();
                      else
                        setFieldValue("onboarding.CompliancePermission", !values?.onboarding?.CompliancePermission);
                    }}                 
                  />
                  <FormikFieldLeftAligned
                        name="onboarding.ResupplyPermission"
                        checked={!!values?.onboarding?.ResupplyPermission}
                        label="Resupply"
                        type="checkbox"
                        onChange={() => {
                          if (!onboardingEnabled)
                            document.getElementById("onboardingEmptyWarning").click();                       
                          else
                            setFieldValue("onboarding.ResupplyPermission", !values?.onboarding?.ResupplyPermission);
                    }} 
                    />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Order Supplies"
                    subLabel="Allow users to create orders, display order insights, allow outreach emails/txts"
                  />
                  <FormikFieldLeftAligned
                    name="orderSupplies.ResupplyPermission"
                    checked={!!values?.orderSupplies?.ResupplyPermission}
                    label="Resupply only"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="View Orders"
                    subLabel="Mobile app view order history"
                  />
                  <FormikFieldLeftAligned
                    name="myOrders.ResupplyPermission"
                    checked={!!values?.myOrders?.ResupplyPermission}
                    label="Resupply Only"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="My Products"
                    subLabel="Mobile app view patients current equipment"
                  />
                  <FormikFieldLeftAligned
                    name="myProducts.CompliancePermission"
                    checked={!!values?.myProducts?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="myProducts.ResupplyPermission"
                    checked={!!values?.myProducts?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="View Tracking"
                    subLabel="Patient App view order tracking"
                  />
                  <FormikFieldLeftAligned
                    name="tracking.ResupplyPermission"
                    checked={!!values?.tracking?.ResupplyPermission}
                    label="Resupply only"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Documents"
                    subLabel="View and upload patient documents"
                  />
                  <FormikFieldLeftAligned
                    name="documents.CompliancePermission"
                    checked={!!values?.documents?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="documents.ResupplyPermission"
                    checked={!!values?.documents?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Sign Documents"
                    subLabel="View signature history and sign documents"
                  />
                  <FormikFieldLeftAligned
                    name="signatures.CompliancePermission"
                    checked={!!values?.signatures?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="signatures.ResupplyPermission"
                    checked={!!values?.signatures?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Chat Now"
                    subLabel="Mobile App - request immediate text chat with coach"
                  />
                  <FormikFieldLeftAligned
                    name="chatNow.CompliancePermission"
                    checked={!!values?.chatNow?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="chatNow.ResupplyPermission"
                    checked={!!values?.chatNow?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Schedule Chat"
                    subLabel="Schedule text/video/product demo chat with dme"
                  />
                  <FormikFieldLeftAligned
                    name="scheduleChat.CompliancePermission"
                    checked={!!values?.scheduleChat?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="scheduleChat.ResupplyPermission"
                    checked={!!values?.scheduleChat?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Questionnaires"
                    subLabel="View and complete assigned questionnaires"
                  />
                  <FormikFieldLeftAligned
                    name="questionaires.CompliancePermission"
                    checked={!!values?.questionaires?.CompliancePermission}
                    label="Compliance"
                    type="checkbox"
                  />
                  <FormikFieldLeftAligned
                    name="questionaires.ResupplyPermission"
                    checked={!!values?.questionaires?.ResupplyPermission}
                    label="Resupply"
                    type="checkbox"
                  />
                </div>
                <FormikField
                  name="maskFitting"
                  checked={!!values?.maskFitting}
                  label={
                    <CheckBoxLabel
                      label="Mask Fittings"
                      subLabel="Request and manage sleepglad patient mask fitting scans"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="patientTasks"
                  checked={!!values?.patientTasks}
                  label={
                    <CheckBoxLabel
                      label="Patient Tasks"
                      subLabel="Ability to assign/create and view patient Tasks"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="new_hcpc_eligibility"
                  checked={!!values?.new_hcpc_eligibility}
                  label={
                    <CheckBoxLabel
                      label="New HCPC Eligibility"
                      subLabel="Ability to view patient HCPC order history tab and eligiblity"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="dmeAccessRecordings"
                  checked={!!values?.dmeAccessRecordings}
                  label={
                    <CheckBoxLabel
                      label="Allow Recording Access"
                      subLabel="Ability for company admins to view patient call recordings"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Allow Autoship Orders"
                    subLabel="Daily automated task"
                  />
                  <FormikFieldLeftAligned
                    name="allowAutoshipOrders"
                    checked={!!values?.allowAutoshipOrders}
                    label="Resupply only"
                    type="checkbox"
                    style={{ display: "flex" }}
                  />
                </div>
                <div className="form-field-container">
                  <CheckBoxLabel
                    label="Document Hub"
                    subLabel="Allow users to see in the left navigation panel and see documents."
                  />
                  <FormikFieldLeftAligned
                    name="document_hub"
                    checked={!!values?.document_hub}
                    type="checkbox"
                    style={{ display: "flex" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1
                }}
              >
                <h4>Integrations</h4>
                <FormikField
                  name="bonafideInsuranceVerification.ResupplyPermission"
                  checked={
                    !!values?.bonafideInsuranceVerification.ResupplyPermission
                  }
                  label={
                    <CheckBoxLabel
                      label="Bonafide Insurance Verification"
                      subLabel="Insurance Verification integration only"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
                <FormikField
                  name="ra_cmn_integration"
                  checked={!!values?.ra_cmn_integration}
                  label={
                    <CheckBoxLabel
                      label="Cmn Workflow - Referral Answer"
                      subLabel="Access to RA Cmns"
                    />
                  }
                  type="checkbox"
                  style={{ display: "flex" }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                margin: 5,
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <button
                type="button"
                className="button warning"
                disabled={!dirty}
                onClick={handleReset}
              >
                Reset
              </button>
              <button type="submit" disabled={!dirty || isSubmitting}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <PureModal
        renderTrigger={({ openModal }) => (
          <div onClick={() => openModal()} id="onboardingEmptyWarning"></div>
        )}
        renderContent={({ closeModal }) => (
          <ConfirmationModal
            title="Onboarding permission will remain disabled until at least one tile is configured."
            closeModal={closeModal}
            notNowAction={() => {}}
            onlyShowOneButton={true}
          />
        )}
      ></PureModal>
    </div>
  );
};

CompanyAppPermissions.propTypes = { companyId: PropTypes.string.isRequired };

const CheckBoxLabel = ({ label, subLabel }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      marginRight: 20,
      marginBottom: "1.5em",
      color: "#4d4d4d"
    }}
  >
    <span style={{ fontSize: 18 }}>{label}</span>
    <sub style={{ fontSize: 14, marginBottom: 5 }}>{subLabel}</sub>
  </div>
);

CheckBoxLabel.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string
};

export default CompanyAppPermissions;
