import React from "react";
import PropTypes from "prop-types";
import TabLink from "components/ui/tab-list/tab-link";
import { HasPermission } from "components/shared/app-permission";
import HasRolePermission from "components/shared/has-role-permission";

const CompanyTabs = ({ id, managesCompliance, canAccessDocumentHub }) => (
  <div className="company-menu no-print">
    <ul className="tab-list">
      <TabLink path={`/companies/${id}/profile`} label="Profile" exact={true} />
      <HasRolePermission contractorCheck={true}>
        <HasRolePermission
          // allowedRoles={["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]}
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin"
          ]}
        >
          <HasPermission permission="orderSupplies">
            <TabLink path={`/companies/${id}/profile/orders`} label="Orders" />
          </HasPermission>
        </HasRolePermission>
        <TabLink path={`/companies/${id}/profile/patients`} label="Patients" />
        <TabLink path={`/companies/${id}/profile/contacts`} label="Contacts" />
        <HasRolePermission
          // allowedRoles={["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]}
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin"
          ]}
        >
          <HasPermission permission="orderSupplies">
            <TabLink
              path={`/companies/${id}/profile/monthly-report`}
              label="Monthly Report"
            />
          </HasPermission>
        </HasRolePermission>
        <HasRolePermission
          // allowedRoles={["Administrator", "CompanyAdministrator", "ServiceAdmin", "ContractorAdministrator"]}
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin"
          ]}
        >
          <TabLink
            path={`/companies/${id}/profile/patient-demographic-logs`}
            label="Patient Demographic Logs"
          />
        </HasRolePermission>
        <TabLink
          path={`/companies/${id}/profile/inventory`}
          label="Inventory"
        />
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "OfficeAdministrator",
            "ServiceCoach",
            "ServiceAdmin",
            "SleepCoach"
            // ,'ContractorAdministrator'
          ]}
        >
          <HasPermission permission="maskFitting">
            <TabLink
              path={`/companies/${id}/profile/mask-fitting`}
              label="Mask Fittings"
            />
          </HasPermission>
        </HasRolePermission>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <TabLink
            path={`/companies/${id}/profile/app-permissions`}
            label="App Permissions"
          />
        </HasRolePermission>
        <HasPermission permission="orderSupplies">
          <TabLink path={`/companies/${id}/profile/exports`} label="Exports" />
        </HasPermission>
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "OfficeAdministrator",
            "ServiceCoach",
            "ServiceAdmin"
            // ,'ContractorAdministrator'
          ]}
        >
          <TabLink
            path={`/companies/${id}/profile/insurance`}
            label="Insurance"
          />
        </HasRolePermission>
        <HasRolePermission allowedRoles={["Administrator"]}>
          <TabLink
            path={`/companies/${id}/profile/automated-jobs`}
            label="Automated Jobs"
          />
        </HasRolePermission>
        <TabLink
          path={`/companies/${id}/profile/complianceForms`}
          label="Forms"
        />
        {managesCompliance && (
          <TabLink
            path={`/companies/${id}/profile/compliance-dashboard`}
            label="Compliance Dashboard"
          />
        )}
        <TabLink
          path={`/companies/${id}/profile/onboarding`}
          label="Onboarding"
        />
      </HasRolePermission>
      {canAccessDocumentHub && (
        <HasRolePermission
          allowedRoles={[
            "Administrator",
            "CompanyAdministrator",
            "ServiceAdmin"
          ]}
        >
          <TabLink
            path={`/companies/${id}/profile/document-hub`}
            label="Document Hub"
          />
        </HasRolePermission>
      )}
    </ul>
  </div>
);

CompanyTabs.propTypes = {
  id: PropTypes.string.isRequired,
  userAppPermissions: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
  managesCompliance: PropTypes.bool
};

export default CompanyTabs;
