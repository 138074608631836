import React from "react";

const DocumentHubViewTab = ({ fileUrl }) => {
  return (
    <div>
      <iframe src={fileUrl} style={{ width: "100%", height: "500px" }}></iframe>
    </div>
  );
};

export default DocumentHubViewTab;
